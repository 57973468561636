<template>
    <div>
        <div id="checkbox-product" class="booking-section">
            <h4>01. {{$t('common.select_site')}}</h4>

            <div class="form-group"  :class="{'has-error': errorSelectedProducts}">
                <div class="checkbox" v-for="product in productsOptions" :key="product.value">
                    <label>
                        <input type="checkbox" :value="product.value" v-model.number="checkedProducts[product.value]">
                        {{product.name}}
                    </label>
                </div>
                <span v-if="errorSelectedProducts" class="help-block">{{$t('error.field_required')}}</span>
            </div>
        </div>
        <div class="booking-section">
            <h4 style="margin-bottom:20px;">02. {{$t('common.select_date_hour')}}</h4>
            <a v-show="!customizingDate" id="custom_datetime_btn" class="btn btn-default btn-block" @click="customizingDate=true"><span class="glyphicon glyphicon-calendar" aria-hidden="true"></span> Personalizza Data e Orario</a>
            <div v-show="customizingDate" id="section_date">
                <div class="form-group has-feedback">
                    <Datetime v-if="onlyDate"
                            type="date"
                            :value-zone="TIMEZONE_DEFAULT"
                            :zone="TIMEZONE_DEFAULT"
                            v-model="newReservation.date"
                            input-class="form-control"
                    ></Datetime>
                    <Datetime v-else
                              type="datetime"
                              :value-zone="TIMEZONE_DEFAULT"
                              :zone="TIMEZONE_DEFAULT"
                              v-model="newReservation.date"
                              input-class="form-control"
                    ></Datetime>
                    <span aria-hidden="true" class="glyphicon glyphicon-calendar form-control-feedback"></span>
                </div>
            </div>
        </div>
        <div class="booking-section">
            <h4>03. {{$t("common.select_rate")}}</h4>
            <div class="row">
                <div id="container_rates">
                    <div class="form-group" :class="{'has-error': errorRates}">
                    <div class="row_rate" v-for="(rateInstance, index) in rateInstances" :key="index">
                        <div class="col-xs-7">
                            <label>{{$t("common.rate")}}</label>
                            <select class="form-control rate_option" v-model.number="rateInstance.rate">
                                <option v-for="(rate, index) in ratesOptions" :value="rate.value" :key="index" :disabled="rate.value === undefined">
                                    {{rate.name}} <span v-if="rate.value !== undefined">- €{{rate.price}}</span>
                                </option>
                            </select>
                        </div>
                        <div class="col-xs-4">
                            <div class="form-group">
                                <label>{{$t("common.amount")}}</label>
                                <input name="qunatity" type="number" class="form-control quantity" min="1" v-model.number="rateInstance.quantity" data-number="0">
                            </div>
                        </div>
                        <div class="col-xs-1" v-if="index > 0">
                            <div class="form-group"><a @click.prevent.stop="removeRateInstance(index)" style="margin-top: 25px;" data-number="1" class="btn btn-danger delete-row">x</a></div>
                        </div>
                        <div class="clearfix margin-bottom-10"></div>
                    </div>
                    <div class="col-xs-12">
                        <span v-if="errorRates" class="help-block">{{$t('error.rates_not_valid')}}</span>
                    </div>
                    </div>
                </div>
                <div class="col-xs-12">
                    <a id="add_row_btn" style="margin-top:10px;" class="btn btn-default" @click.prevent.stop="addNewRateInstance">
                        <span class="glyphicon glyphicon-plus" aria-hidden="true"></span> {{$t("common.add_rate")}}
                    </a>
                </div>
            </div>
        </div>
        <div v-if='checkedProductsNum == productsOptions.length && discountVisible' class="booking-section">
            <h4 style="margin-bottom:20px;">Sconti Famiglia</h4>
            <input type="checkbox" id='10' v-model="discountVal10"> Genitori con 1-2 figli sopra i 12 anni - Sconto 10%
            <br>
            <input type="checkbox" id='20' v-model="discountVal20"> Genitori con 3 o più figli sopra i 12 anni - Sconto 20%
        </div>
        <div class="booking-section" v-if="showClientSection">
            <h4>04. Informazioni Cliente</h4>
            <div class="row margin-bottom-15">
                <div class="col-xs-12">
                    <div class="form-group">
                        <label for="nationality">Nazione*</label>
                        <SelectCountry @country-changed="countryChanged"></SelectCountry>

                    </div>
                </div>
            </div>
            <a class="btn btn-default margin-bottom-15" role="button" data-toggle="collapse" href="#collapseCustomerData" aria-expanded="false" aria-controls="collapseCustomerData"><span class="glyphicon glyphicon-plus" aria-hidden="true"></span> Inserisci Dati Cliente</a>
            <div class="collapse" id="collapseCustomerData">
                <div class="row">
                    <div  v-bind:class="[isInvoiceType() ? 'col-xs-12' : 'col-xs-6']">
                        <div class="form-group">
                            <label for="fname">Nome o Azienda</label>
                            <input type="text" class="form-control" id="fname" v-model="newReservation.first_name">
                        </div>
                    </div>
                    <div class="col-xs-6" v-if="!isInvoiceType()">
                        <div class="form-group">
                            <label for="lname">Cognome</label>
                            <input type="text" class="form-control" id="lname" v-model="newReservation.last_name">
                        </div>
                    </div>
                </div>
                <div class="row margin-top-20 margin-bottom-15">
                    <div class="col-xs-12">
                        <div class="form-group">
                            <label for="email">Email</label>
                            <input type="email" class="form-control" id="email" v-model="newReservation.email">
                        </div>
                        <div class="form-group">
                            <label for="phone">Telefono</label>
                            <VuePhoneNumberInput
                                    @update="updatePhoneNumber"
                                    no-validator-state
                                    default-country-code="IT"
                                    v-model="phone_number_not_international"
                                    :translations="Utils.getTranslationsPhoneField()"
                                    id="phone"></VuePhoneNumberInput>
                        </div>
                    </div>
                </div>
                <div v-if="isInvoiceType()">
                    <div class="row margin-top-20 margin-bottom-15">
                        <div class="col-xs-12">
                            <div class="form-group">
                                <label for="customer_vat_number">Partita IVA</label>
                                <input type="text" class="form-control" id="customer_vat_number" v-model="newReservation.customer_vat_number">
                            </div>
                        </div>
                    </div>
                    <div class="row margin-top-20 margin-bottom-15">
                        <div class="col-xs-12">
                            <div class="form-group">
                                <label for="customer_pec">PEC</label>
                                <input type="email" class="form-control" id="customer_pec" v-model="newReservation.customer_pec">
                            </div>
                        </div>
                    </div>
                    <div class="row margin-top-20 margin-bottom-15">
                        <div class="col-xs-12">
                            <div class="form-group">
                                <label for="customer_address">Indirizzo</label>
                                <input type="email" class="form-control" id="customer_address" v-model="newReservation.customer_address">
                            </div>
                        </div>
                    </div>
                    <div class="row margin-top-20 margin-bottom-100">
                        <div class="col-xs-12">
                            <div class="form-group">
                                <label for="customer_recipient_code">Codice Destinatario</label>
                                <input type="email" class="form-control" id="customer_recipient_code" v-model="newReservation.customer_recipient_code">
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
    import {TIMEZONE_DEFAULT} from "../../common/constants";
    import VuePhoneNumberInput from 'vue-phone-number-input';
    import 'vue-phone-number-input/dist/vue-phone-number-input.css'
    import baseMixin from "../../common/baseMixin";
    import {Datetime} from "vue-datetime";
    import 'vue-datetime/dist/vue-datetime.css'
    import SelectCountry from "../common/SelectCountry";
    import {RATE_OTHER_FREE_INDEX} from "../../common/keys";

    export default {
        name: "BookingForm",
        loading: false,
        mixins: [baseMixin],
        components: {VuePhoneNumberInput, Datetime, SelectCountry},
        props: {
            onlyDate: {
                type: Boolean,
                default: false
            },
            otherFreeRateShown: {
                type: Boolean,
                default: true
            },
            showClientSection: {
                type: Boolean,
                default: true
            },
            showCustomizingDate: {
                type: Boolean,
                default: false
            },
            errorSelectedProducts: {
                type: Boolean,
                default: false
            },
            errorRates: {
                type: Boolean,
                default: false
            },
            discountVisible: {
                type: Boolean,
                default: true
            },
            documentType:{
                type: Number,
                default: 1
            }

        },
        data: () => {
            return {
                checkedProducts: {},
                customizingDate: false,
                TIMEZONE_DEFAULT: TIMEZONE_DEFAULT,
                rateInstances: [],
                checkedProductsNum: 0,
                addRateInstanceIndex: 0,
                discountVal10: false,
                discountVal20: false,

                productsOptions: [],

                newReservation: {
                    reservation_items: [],
                    first_name: null,
                    email: null,
                    last_name: null,
                    phone_number: null,
                    country: null,
                    date: null,
                    discount: 0,
                    customer_vat_number: null,
                    customer_pec: null,
                    customer_address: null,
                    customer_recipient_code: null,
                },
                phone_number_not_international: null,
            }
        },

        computed: {
            ratesOptions: function ()  {
                let rateOptions = this.Keys.RATES_OPTIONS();
                if (!this.otherFreeRateShown) {
                    rateOptions.splice( RATE_OTHER_FREE_INDEX,1)
                }
                return rateOptions;
            }
        },

        mounted() {
            this.productsOptions = this.Keys.PRODUCTS_OPTIONS();

            this.addNewRateInstance();
            this.customizingDate = this.showCustomizingDate;

            if (this.customizingDate) {
                this.newReservation.date =new Date().toISOString();
            }
        },

        methods: {
            isInvoiceType(){
                return this.documentType === this.Keys.DOCUMENT_TYPES.INVOICE
            },
            async regenerateReservationItems(checkedProducts, ratesInstance) {
                this.newReservation.reservation_items = [];

                for (const valueRate of ratesInstance) {
                    for (const [keyProduct, valueProduct] of Object.entries(checkedProducts)) {
                        if (valueProduct) {
                          let quantity = valueRate.quantity
                          if (!Number.isInteger(quantity)) {
                            quantity = 0
                          }
                            this.newReservation.reservation_items.push(
                                {
                                    "product_id": parseInt(keyProduct),
                                    "rate": valueRate.rate,
                                    "quantity": quantity
                                }
                            )
                        }
                    }
                }
                //TODO estimate again

            },

            addNewRateInstance() {

             let rateKeys = Object.keys(this.Keys.RATES);
             let currentRate = this.Keys.RATES[rateKeys[this.addRateInstanceIndex % rateKeys.length]]



              let rateInstance = {
                    rate: currentRate.value,
                    quantity: 1
                };
                this.rateInstances.push(rateInstance);

                this.addRateInstanceIndex++
            },

            removeRateInstance(index) {
                this.rateInstances.splice(index, 1);
            },
            updatePhoneNumber(result) {
                this.newReservation.phone_number = result.formatInternational;
            },
            countryChanged(newCountry) {
                this.newReservation.country = newCountry;
            },

        },
        watch: {
            checkedProducts: {
                handler(newCheckedProducts) {
                    this.regenerateReservationItems(newCheckedProducts, this.rateInstances)
                },
                deep: true,
            },
            rateInstances: {
                handler(newRateInstances) {
                    this.regenerateReservationItems(this.checkedProducts, newRateInstances)
                },
                deep: true,
            },
            discountVal10(value) {
                if(value){
                    this.discountVal20 = false;
                    this.newReservation.discount = 10
                } else if (this.discountVal20 === false) {
                    this.newReservation.discount = 0
                }
            },
            discountVal20(value) {
                if(value){
                    this.discountVal10 = false;
                    this.newReservation.discount = 20
                } else if (this.discountVal10 === false) {
                    this.newReservation.discount = 0
                }
            },

            newReservation: {
                handler(newReservationChanged) {
                    var counter = 0;
                    for (const [, valueProduct] of Object.entries(this.checkedProducts)) {
                        if (valueProduct) {
                            counter +=1;
                        }
                    }
                    this.checkedProductsNum = counter;
                    if(this.checkedProductsNum < this.productsOptions.length){
                        this.newReservation.discount = 0;
                        this.discountVal10 = false;
                        this.discountVal20 = false;
                    }
                    this.$emit('reservation-data-changed', newReservationChanged)
                },
                deep: true,
            },
            customizingDate: function (newVal) {
                if (newVal) {
                    this.newReservation.date =new Date().toISOString();
                }
            }
        }
    }
</script>

<style scoped>

</style>
